var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_c('span', [_vm._v("A Vue.js component for ApexCharts. Read full documnetation")]), _c('b-link', {
    attrs: {
      "target": "_blank",
      "href": "https://apexcharts.com/docs/installation/"
    }
  }, [_vm._v(" here ")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apex-line-area-chart')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apex-data-bar-chart')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apex-scatter-chart')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apex-line-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('apex-bar-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('apex-candlestick-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('apex-heat-map-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('apex-radial-bar-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('apex-radar-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('apex-donut-chart')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }