var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Expense Ratio ")]), _c('b-card-sub-title', {
    staticClass: "mb-2"
  }, [_vm._v(" Spending on various categories ")]), _c('vue-apex-charts', {
    attrs: {
      "type": "donut",
      "height": "350",
      "options": _vm.apexChatData.donutChart.chartOptions,
      "series": _vm.apexChatData.donutChart.series
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }