var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Line Chart ")]), _c('b-card-sub-title', [_vm._v("Commercial networks")])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "16"
    }
  }), _c('flat-pickr', {
    staticClass: "form-control flat-picker bg-transparent border-0 shadow-none",
    attrs: {
      "config": {
        mode: 'range'
      },
      "placeholder": "YYYY-MM-DD"
    },
    model: {
      value: _vm.rangePicker,
      callback: function callback($$v) {
        _vm.rangePicker = $$v;
      },
      expression: "rangePicker"
    }
  })], 1)]), _c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "area",
      "height": "400",
      "options": _vm.apexChatData.lineAreaChartSpline.chartOptions,
      "series": _vm.apexChatData.lineAreaChartSpline.series
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }