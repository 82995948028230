var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Balance ")]), _c('b-card-sub-title', [_vm._v("Commercial networks & enterprises")])], 1), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap mt-sm-0 mt-1"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-0 mr-1"
  }, [_vm._v(" $ 100,000 ")]), _c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_c('feather-icon', {
    staticClass: "text-danger mr-25",
    attrs: {
      "icon": "ArrowDownIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("20%")])], 1)], 1)]), _c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "400",
      "options": _vm.apexChatData.lineChartSimple.chartOptions,
      "series": _vm.apexChatData.lineChartSimple.series
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }