var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Mobile Comparison"
    }
  }, [_c('vue-apex-charts', {
    attrs: {
      "type": "radar",
      "height": "400",
      "options": _vm.apexChatData.radarChart.chartOptions,
      "series": _vm.apexChatData.radarChart.series
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }